<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h6>
        {{ `${$t('fields.result')} (${hiddenList.length === selectedLimit ? hiddenList.length + "+" : hiddenList.length})` }}
      </h6>
      <b-button v-if="type==='deposit' && $allowPermission('agent:manual.deposit')" variant="success" @click="onToggleTopupManual({})">
        ฝากมือ
      </b-button>
      <h6 v-if="type==='withdraw'">
        หากขึ้นข้อความว่า “<span class="text-danger">เกิดข้อผิดพลาดจาก SCB</span>” กรุณารอสักครู่ 5-10 นาทีแล้วทำรายการใหม่อีกครั้ง
      </h6>
    </div>
    <b-row class="mb-3">
      <b-col>
        <LimitSelect v-model="selectedLimit"/>
        <span v-if="isFetching" class="text-muted">กำลังโหลด...</span>
      </b-col>
       <b-col v-if="isOwner" cols="3">
        <MasterSelectInput 
          :value="selectedMaster"
          hide-label
          @update="onMasterIdChange"
        />
      </b-col>
      <b-col v-if="isOwner || isMaster" cols="3">
        <AgentsSelectInput
          :value="selectedAgent"
          :master-id="selectedMaster"
          hide-label
          @update="onAgentIdChange"
        />
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      :items="hiddenList"
      :fields="type==='deposit'? depositFields: withdrawFields"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      
      <template #cell(detail)="data">
        <span v-if="data.value" class="text-danger"> {{ data.value }}</span>
        <span v-else>-</span>
      </template>
      <template #cell(transactionDateTime)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(createdAt)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(fromBankCode)="data">
        <b-media vertical-align="center">
          <template #aside>
            <BankIcon :bank-code="data.value"/>
          </template>
          {{ data.item.fromAccountNumber }}<br>
          {{ data.item.fromAccountName}}
        </b-media>
      </template>
      <template #cell(userUsername)="data">
        <component :is="$allowPermission('agent:manage.user') && type==='withdraw' && data.item.userId ? 'a' : 'span'" :href="`/players/detail/${data.item.userId}`" target="_blank">
          <b-media vertical-align="center">
            {{ data.value.substring(0, 3) + 'xxx' + data.value.substring(6, 10) }}<br>
          </b-media>
        </component>
      </template>
      <template #cell(toBankAccountNumber)="data">
        <component :is="$allowPermission('agent:manage.user') && type==='withdraw' && data.item.userId ? 'a' : 'span'" :href="`/players/detail/${data.item.userId}`" target="_blank">
          <b-media vertical-align="center">
            <template #aside>
              <BankIcon :bank-code="data.item.toBankCode"/>
            </template>
            {{ data.value }}<br>
            {{ data.item.userFirstname }} {{ data.item.userLastname }}
          </b-media>
        </component>
      </template>
      <template #cell(agentBankAccount)="data">
        <b-media vertical-align="center">
          <template #aside>
            <BankIcon :bank-code="data.value.bankCode"/>
          </template>
          {{ data.value.bankAccountNumber }}<br>
          {{ data.value.bankAccountName}}
        </b-media>
      </template>
       <template #cell(transactionChannelCode)="data">
        <b-badge>
          {{ data.item.transactionChannelDescription || '-' }}
          ({{ data.value || '-' }})
        </b-badge>
      </template>
      <template #cell(afterBalance)="data">
        <b-badge class="badge-soft-danger">
          {{ data.item.beforeBalance | currency }}
        </b-badge><br>
        <b-badge class="badge-soft-success">
          {{ data.value | currency }}
        </b-badge>
      </template>
      
      <template #cell(operation)="data">
        <b-button-group size="sm">
          <b-button
            variant="info"
            @click="onApprovePendingCredit(data.item, 'adjust')"
          >
            ปรับยอด
          </b-button>
          <b-button
            variant="danger"
          >
           {{ $t('buttons.remove') }}
          </b-button>
        </b-button-group>
      </template>
      <template #cell(operation2)="data">
        <b-button-group size="sm">
          <b-button-group size="sm">
          <b-button
            variant="info"
            @click="onApproveWithdraw(data.item, 'auto')"
          >
            ถอนออโต้
          </b-button>
          <b-button variant="warning" @click="onApproveWithdraw(data.item, 'manual')">
           ถอนมือ
          </b-button>
           <b-button
            variant="danger"
            @click="onRejectPendingCredit(data.item.id)"
          >
           ปฎิเสธ
          </b-button>
        </b-button-group><br>
        </b-button-group><br>
        <b-button-group size="sm">
           <b-button
            variant="light"
            @click="$router.push(`/credits/all?search=${data.item.user.username}`)"
          >
            รายงานเครดิต
          </b-button>
        </b-button-group>
      </template>
      <template #empty="">
        <p class="text-center text-muted">{{ $t('messages.nothing_here') }}</p>
      </template>
    </b-table>
    <!-- pagination -->
    <SimplePagination :per-page="limit" :has-more-page="pagination.itemCount >= selectedLimit" @update="(val) => currentPage = val"/>

    <approve-withdraw-modal :txn="selectedTxn" :approve-type="approveType"></approve-withdraw-modal>
<!--    <topup-manual-modal :txn="selectedTxn"></topup-manual-modal>-->
    <reject-withdraw-modal :txn-id="selectedTxnId"></reject-withdraw-modal>
  </b-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'PendingTransactions',
  components: {
    SimplePagination: () => import('@components/commons/simple-pagination'),
    MasterSelectInput: () =>
      import('@components/master/master-select-input'),
    AgentsSelectInput: () =>
      import('@components/agents/agents-select-input'),
    ApproveWithdrawModal: () =>
      import('@components/transactions/approve-withdraw-modal.vue'),
    RejectWithdrawModal: () => import('@components/transactions/reject-withdraw.modal.vue'),
  },
  props: {
    type: {
      type: String,
      default: 'deposit',
    },
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      selectedTxn: {},
      depositFields: [
        '#',
        {
          key: 'channel',
          label: 'ช่องทาง',
        },
        {
          key: 'bank_code',
          label: 'บัญชีโอน',
        },
        {
          key: 'receive',
          label: 'บัญชีรับ',
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
        },
        {
          key: 'remain',
          label: 'คงเหลือ (ก่อน-หลัง)',
          class: 'text-right',
        },
        {
          key: 'created_at',
          label: 'เวลาโอน',
        },
        {
          key: 'operation',
          label: `${this.$t('buttons.operation')}`,
          class: 'text-right',
        },
      ],
      withdrawFields: [
        '#',
        {
          key: 'userUsername',
          label: 'ยูสเซอร์เนม',
        },
        {
          key: 'toBankAccountNumber',
          label: 'บัญชี',
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
        },
        {
          key: 'remark',
          label: 'หมายเหตุ'
        },
        {
          key: 'createdAt',
          label: 'เวลาแจ้ง',
        },
        {
          key: 'operation2',
          label: `${this.$t('buttons.operation')}`,
          class: 'text-right',
        },
      ],
      rejectForm: { txnId: '', detail: '', isReturnCredit: false },
      approveType: 'adjust',
      selectedTxnId: '',
      selectedMaster: '',
      selectedAgent: '',
    }
  },
  computed: {
    ...mapState({
      isFetchingWithdrawHide: (state) => state.withdraw.isFetchingWithdrawHide,
      isRejecting: (state) => state.credit.isRejectingPlayerWithdraw,
      isRejectingSuccess: (state) =>
        state.credit.isRejectingPlayerWithdrawSuccess,
    }),
    ...mapGetters(['isAgent', 'isOwner', 'isMaster', 'withdrawHide']),
    hiddenList() {
      return this.withdrawHide.items
    },
    pagination() {
      return this.withdrawHide.meta || {itemsPerPage: 0,totalItems: 0 }
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    isFetching() {
      return this.isFetchingWithdrawHide
    },
  },
  watch: {
    currentPage(val) {
       if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
    isRejectingSuccess(val) {
      if (val) {
        this.onCloseRejectModal()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions([
      'fetchWithdrawHide',
      'rejectPlayerWithdraw',
      'acceptPlayerWithdraw',
      'manualSetStatusToSuccess',
    ]),
    fetchData() {
      if(!this.isAgent && !this.selectedAgent.length) return
      this.fetchWithdrawHide({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: '',
        masterId: this.selectedMaster,
        agentId: this.selectedAgent,
      })
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.$emit('on-agent-change', agentId)
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.$emit('on-master-change', masterId)
      this.fetchData()
    },
    onApproveWithdraw(txn, type) {
      this.$bvModal.show('approve-withdraw-modal')
      this.selectedTxn = txn
      this.approveType = type
    },
    onRejectPendingCredit(id) {
      this.$bvModal.show('reject-withdraw-modal')
      this.selectedTxnId = id
    },
    onApprovePendingCredit(txn, type) {
      this.$bvModal.show('approve-withdraw-modal')
      this.selectedTxn = txn
      this.approveType = type
    },
    onToggleTopupManual(txn) {
      this.selectedTxn = txn
      // this.$bvModal.show('topup-manual-modal')
    },
  },
}
</script>
